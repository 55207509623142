const projects = [
  {
    id: 1,
    name: "Ethosphere",
    description:
      "A dynamic web application that leverages Natural Language Processing (NLP) to facilitate meaningful discussions among like-minded individuals",
    skills: [
      "react",
      "reactjs",
      "javascript",
      "css",
      "api",
      "restframework",
      "python",
      "es6",
      "arrowfunctions",
      "django",
      "dbms",
      "postresql",
      "sql",
      "bert",
      "jaccard",
      "numpy",
      "pandas",
      "featureengineering",
      "databasemanagement",
      "frontend",
      "backend",
      "git",
      "versioncontrol",
      "machinelearning",
      "artificialintelligence",
      "tensorflow",
      "pytorch",
      "logo",
      "graphicdesigning",
      "uiux",
      "uxdesign",
      "uidesign",
      "bootstrap",
      "nlp","authentication",
      "webtokens",
      "fullstack",
      "fullstackdevelopment",
      "database",
      "git",
      "versioncontrol",
      "webapp",
      "website","uiux"
    ],
    highlights: [
      "Python",
      "NLP",
      "BERT",
      "Word Embeddings",
      "Django",
      "ReactJS",
      "Redux",
      "API",
      "REST Framework",
      "Authentication",
      "JavaScript",
      "HTML/CSS",
    ],
    imageURL: "./images/portfolio-ethosphere_2.png", // Path to project screenshot
    link: "https://youtu.be/eButwPi-574",
    linkText: "Watch Featurette",
    achievements: ["Acknowledged as Best Project by Prof. Paea LePendu"],
    location: "California, USA",
    timePeriod: "December 2023",
    details:["Test1","Test2"]
    
    // other project-related properties
  },
  {
    id: 2,
    name: "Plagiarism detection in Contextual Meaning",
    description:
      "Identifies plagiarism by scoring the similarity of communities within textual content. The approach provides a context-aware and nuanced analysis, surpassing traditional methods, with potential applications in academic and online content evaluation",
    skills: [
      "python",
      "bert",
      "numpy",
      "pandas",
      "featureengineering",
      "git",
      "versioncontrol",
      "tensorflow",
      "pytorch",
      "nlp",
      "research"
    ],
    highlights: ["Research", "Python", "NLP", "BERT", "Word Embeddings"],
    imageURL: "./images/portfolio-plagiarism.png", // Path to project screenshot
    link: "https://link.springer.com/chapter/10.1007/978-981-99-0838-7_67",
    linkText: "View Publication",
    achievements: [],
    location: "Coimbatore, India",
    timePeriod: "October 2022",
    details:["Test1","Test2"]
    // other project-related properties
  },
  {
    id: 3,
    name: "poRthole",
    description:
      "A groundbreaking navigation web app that operates in real-time, parallelly processing and rendering detected potholes",
    skills: [
      "react",
      "reactjs",
      "javascript",
      "css",
      "api",
      "ai",
      "restframework",
      "python",
      "es6",
      "arrowfunctions",
      "django",
      "dbms",
      "postresql",
      "sql",
      "numpy",
      "pandas",
      "featureengineering",
      "databasemanagement",
      "frontend",
      "backend",
      "git",
      "versioncontrol",
      "machinelearning",
      "artificialintelligence",
      "tensorflow",
      "pytorch",
      "logo",
      "graphicdesigning",
      "uiux",
      "uxdesign",
      "uidesign",
      "computervision",
      "yolo",
      "yolov5",
      "ocr",
      "authentication",
      "webtokens",
      "fullstack",
      "fullstackdevelopment",
      "database",
      "git",
      "versioncontrol",
      "webapp",
      "website","uiux"
    ],
    highlights: [
      "Python",
      "JavaScript",
      "Computer Vision",
      "YOLO v5",
      "Django",
      "ReactJS",
      "API",
      "REST Framework",
    ],
    imageURL: "./images/portfolio-porthole.png", // Path to project screenshot
    link: "https://github.com/rxthxnrxj/porthole",
    linkText: "Github Link",
    achievements: ["Winner - Smart India Hackathon (University) - 2022"],
    location: "Coimbatore, India",
    timePeriod: "March 2022",
    details:["Test1","Test2"]
    // other project-related properties
  },
  {
    id: 4,
    name: "Lab-i-rinth",
    description:
      "Labirinth uses DSP in Python to process voices, mapping frequencies to phonetics for accurate word prediction, aiding communication for individuals with pronunciation difficulties like autism.",
    skills: [
      "digitalsignalprocessing",
      "python",
      "speechprocessing",
      "signalfiltering",
      "shorttimefouriertransform",
      "fastfouriertransform",
      "thresholdingtechniques",
      "machinelearning",
      "understandingofphonetics",
      "communicationalgorithmdesign",
      "problemsolving",
      "dtft",
      "stft",
      "ml",
      "ai",
      "dsp",
    ],
    highlights: ["Python", "DTFT", "STFT", "Machine Learning"],
    imageURL: "./images/portfolio-labirinth.png", // Path to project screenshot
    link: "/projects/labirinth",
    linkText: "Github Link",
    achievements: [],
    location: "Coimbatore, India",
    timePeriod: "November 2021",
    details:["Test1","Test2"]
    // other project-related properties
  },
  {
    id: 5,
    name: "Amazon Search Node Classification",
    description:
      "An NLP pipeline for an LSTM model was implemented on a dataset with over 11 lakhs attributes, encompassing preprocessing (tokenization and lemmatization), bidirectional LSTM modeling, and post-processing.",
    skills: [
      "naturallanguageprocessing",
      "deeplearning",
      "lstm",
      "python",
      "tensorfloworpytorch",
      "tokenization",
      "lemmatization",
      "datapreprocessing",
      "datapostprocessing",
      "machinelearning",
      "datacleaning",
      "nlp",
      "ml",
      "ai",
      "research"
    ],
    highlights: [
      "Python",
      "NLP Pipeline",
      "Machine Learning",
      "Ensemble Model",
    ],
    imageURL: "./images/portfolio-amazonNode.png", // Path to project screenshot
    link: "/projects/",
    linkText: "LINK TO BE POSTED",
    achievements: ["Top 15 position"],
    location: "Chennai, India",
    timePeriod: "August 2021",
    details:["Test1","Test2"]
    // other project-related properties
  },
  {
    id: 7,
    name: "Aidwiser",
    description:
      "An ML based web platform for a smarter routing of ambulances to aids and hospitals.",
    skills: [
      "javascript",
      "css",
      "api",
      "ai",
      "python",
      "es6",
      "arrowfunctions",
      "django",
      "dbms",
      "postresql",
      "sql",
      "lstm",
      "numpy",
      "pandas",
      "databasemanagement",
      "frontend",
      "backend",
      "git",
      "versioncontrol",
      "machinelearning",
      "artificialintelligence",
      "tensorflow",
      "pytorch",
      "logo",
      "graphicdesigning",
      "uiux",
      "uxdesign",
      "uidesign",
      "tomtomapi",
      "openweatherapi","openweather", "tomtom", "js","python","database","dbms","sql","sqlite"
    ],
    highlights: [
      "Python",
      "Supervised Learning",
      "Django",
      "BiDirectional LSTM",
      "RandomForest",
      "Google Maps API",
      "TomTom API",
      "OpenWeather API",
    ],
    imageURL: "./images/portfolio-aidwiser.png", // Path to project screenshot
    link: "https://aidwiserproject.web.app/",
    linkText: "Learn More",
    achievements: [
      "2nd place (among 400) - Smart City Hackathon (BCIC, Honeywell, ASE)",
    ],
    location: "Bengaluru, India",
    timePeriod: "October 2021",
    details:["Test1","Test2"]
    // other project-related properties
  },
  {
    id: 6,
    name: "Honeywell - OCR",
    description:
      "OCR using a LeNet model was implemented to extract data from Honeywell's visionary project models, trained on a custom dataset created by replicating fake terrain backgrounds for alphabets using Adobe Photoshop.",
    skills: [
      "ocr",
      "lenetmodel",
      "datatraining",
      "customdataset",
      "adobephotoshop",
      "machinelearning",
      "computervision",
      "python","cv","lenet","data","bigdata","image","opticalcharacterrecognition","pytorch","numpy","dataset","photoshop","adobe","custom"
    ],
    highlights: ["Python", "OCR", "LeNet", "Computer Vision"],
    imageURL: "./images/portfolio-honeywell.png", // Path to project screenshot
    link: "/projects/",
    linkText: "Link to be posted",
    achievements: [],
    location: "Coimbatore, India",
    timePeriod: "February 2021",
    details:["Test1","Test2"]
    // other project-related properties
  },

  {
    id: 8,
    name: "Amritotsavam - Graphic Designing",
    description:
      "Graphic designing contests to create medium to create awareness on various topics",
    skills: [
      "adobe",
      "photoshop",
      "illustrator",
      "logo",
      "graphicdesigning",
      "uiux",
      "uidesign",
    ],
    highlights: ["Graphic Designing", "UI", "Adobe Photoshop"],
    imageURL: "./images/portfolio-amritotsavam.png", // Path to project screenshot
    link: "/projects/",
    linkText: "Link to be posted",
    achievements: ["1st place - Amritotsavam (ASE) - 2021, 2022"],
    location: "Coimbatore, India",
    timePeriod: "2021 / 2022",
    details:["Test1","Test2"]
    // other project-related properties
  },
];

export default projects;

import React, { useState, useEffect } from "react";
import projects from "./projects"; // Import your projects data
import { Col, Row, Button, Container, Accordion, Offcanvas } from "react-bootstrap";
import img1 from "./images/portfolio-ethosphere_4.png";
import img2 from "./images/portfolio-plagiarism_5.png";
import img3 from "./images/portfolio-porthole_4.png";
import img4 from "./images/portfolio-labirinth_4.png";
import img5 from "./images/portfolio-amazonNode_4.png";
import img6 from "./images/portfolio-honeywell_6.png";
import img7 from "./images/portfolio-aidwiser_4.png";
import img8 from "./images/portfolio-amritotsavam_4.png";
import rathinLaptop from "./images/rathinLaptop.png";
import { Link } from "react-router-dom";
import { findBestMatch } from "string-similarity";
import Aos from "aos";
import "aos/dist/aos.css";
import Select from "react-select";
import "./ProjectScreen.css";

const ProjectFilterPage = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [filteringDone, setFilteringDone] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const [forceRerenderKey, setForceRerenderKey] = useState(0);
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const handleMouseMove = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 30;
    const translateY = (offsetY / boundingRect.height - 0.5) * 30;

    card.style.transition = "transform 0.2s ease-out"; // Add a smooth transition

    card.style.transform = `
          translate(${translateX}px, ${translateY}px) scale(1.05) 
        `;
  };
  const handleMouseMoveExtra = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 50;
    const translateY = (offsetY / boundingRect.height - 0.5) * 50;

    const rotateY = (offsetX / boundingRect.width - 0.5) * 15;
    const rotateX = (0.5 - offsetY / boundingRect.height) * 15;

    card.style.transition = "transform 0.5s ease-out"; // Add a smooth transition
    card.style.transform = `
          translate(${translateX}px, ${translateY}px) scale(1.07) rotateX(${rotateX}deg) 
          rotateY(${rotateY}deg)
        `;
    card.style.borderRadius = "10px";
    card.style.marginRight = "1rem";
  };
  const handleMouseLeave = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
  };
  const handleMouseLeaveExtra = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
    card.style.backgroundColor = "white";
    card.style.padding = "0rem";
    card.style.color = "black";
    card.style.marginRight = "0rem";
    card.style.boxShadow = "none";
  };

  const getImagePath = (projectId) => {
    switch (projectId) {
      case 1:
        return img1;
      case 2:
        return img2;
      case 3:
        return img3;
      case 4:
        return img4;
      case 5:
        return img5;
      case 6:
        return img6;
      case 7:
        return img7;
      case 8:
        return img8;
      default:
        return ""; // Return a default image path or handle it as needed
    }
  };
  const [inputSkill, setInputSkill] = useState("");
  const [enteredSkills, setEnteredSkills] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState(projects);

  // Handle input changes for a single skill
  const handleSkillInputChange = (e) => {
    setInputSkill(e.target.value);
  };

  // Add a skill to the enteredSkills array
  const addSkill = () => {
    const newSkill = inputSkill.trim().toLowerCase();
    if (newSkill && !enteredSkills.includes(newSkill)) {
      setEnteredSkills([...enteredSkills, newSkill]);
      setInputSkill("");
    }
  };

  const removeSkill = (skillToRemove) => {
    const updatedSkills = enteredSkills.filter(
      (skill) => skill !== skillToRemove
    );
    setEnteredSkills(updatedSkills);
    filterProjects(); // Re-run the filtering after removing a skill
  };

  // Filter projects based on entered skills
  const filterProjects = () => {
    if (enteredSkills.length > 0) {
      const filteredProjects = projects.filter((project) => {
        const projectSkills = project.skills.map((skill) =>
          skill.toLowerCase()
        );

        // Check if at least one skill has a high similarity with any entered skill
        const matchThreshold = 0.7; // Adjust the threshold as needed
        return enteredSkills.some((enteredSkill) => {
          const bestMatch = findBestMatch(enteredSkill, projectSkills);
          return bestMatch.bestMatch.rating >= matchThreshold;
        });
      });

      setFilteredProjects(filteredProjects);
      setFilteringDone(true);
      setForceRerenderKey((prevKey) => prevKey + 1); // Increment the key to force a re-render
    } else {
      // If no skills are entered, display all projects
      setFilteredProjects(projects);
      setFilteringDone(true);
      setForceRerenderKey((prevKey) => prevKey + 1); // Increment the key to force a re-render
    }
  };

  useEffect(() => {
    filterProjects();
  }, [enteredSkills]);

  useEffect(() => {
    if (filteringDone) {
      setAnimationClass("fade-in"); // Apply the fade-in class
      // Reset the filteringDone state after a delay to clear the message
      const timeoutId = setTimeout(() => {
        setFilteringDone(false);
        setAnimationClass("fade-out"); // Apply the fade-out class
      }, 3000); // Adjust the delay as needed

      return () => clearTimeout(timeoutId);
    }
  }, [filteringDone]);

  return (
    <Container>
      <Accordion className="px-0 mt-3" data-aos="fade-up">
        <Accordion.Header className="px-0 mx-0">
          Wanna hire me ?
        </Accordion.Header>
        <Accordion.Body>
          <label htmlFor="skillInput" style={{ fontSize: "80%" }}>
            Any specific skillset you're looking for ?{" "}
          </label>
          <br></br>

          <input
            type="text"
            id="skillInput"
            value={inputSkill}
            onChange={handleSkillInputChange}
          />
          <Button onClick={addSkill} variant="dark" className="mx-1">
            +
          </Button>
          <br></br>
          <Row className="mt-2">
            <Col>
              {enteredSkills.map((skill) => (
                <Button
                  variant="light"
                  key={skill}
                  style={{ fontSize: "75%" }}
                  className="px-2 py-1 me-2"
                >
                  {skill}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="ms-1"
                    onClick={() => removeSkill(skill)}
                  >
                    X
                  </Button>
                </Button>
              ))}
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion>
      <Offcanvas show={show} onHide={handleClose} className="me-2" placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>

      {filteredProjects.length > 0 ? (
        filteredProjects.map((project) => (
          <Row
            id={project.id}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{ margin: "0 0 10rem 0" }}
            data-aos="fade-left"
            className="mt-5"
          >
            <Col xs={12} sm={12} md={6} xl={6}>
              <img
                src={getImagePath(project.id)}
                alt={project.name}
                className="project-image"
                style={{ maxWidth: "100%" }}
                onMouseMove={handleMouseMoveExtra}
                onMouseLeave={handleMouseLeaveExtra}
              />
            </Col>
            <Col sm={0} xs={0} md={1} xl={1}></Col>
            <Col xs={12} sm={12} md={5} xl={5}>
              <Row
                style={{ textTransform: "uppercase" }}
                className="xsFontSize70 xsMarginTop xsCenter flex-between"
              >
                <Col md={3} xs={3} sm={3} xl={3}>
                  <Link
                    to={`${project.link}`}
                    style={{ color: "black", textDecoration: "none" }}
                    className="linker"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h5
                      style={{ fontWeight: "600", fontSize: "95%" }}
                      data-aos="fade-up"
                    >
                      {project.linkText}
                    </h5>
                  </Link>
                </Col>
                <Col md={3} xs={3} sm={3} xl={3}>
                  <h5
                    style={{ fontWeight: "300", fontSize: "95%" }}
                    data-aos="fade-up"
                  >
                    {" "}
                    {project.location}
                  </h5>
                </Col>
                <Col md={3} xs={3} sm={3} xl={3}>
                  <h5
                    style={{ fontWeight: "300", fontSize: "95%" }}
                    data-aos="fade-up"
                  >
                    {project.timePeriod}
                  </h5>
                </Col>
              </Row>
              <Row className="mt-5">
                <h3 data-aos="fade-up">{project.name}</h3>
                <Row className="">
                  {project.achievements && (
                    <Row className=" project-achievements">
                      {project.achievements.map((achievement, index) => (
                        <Col
                          key={index}
                          xs="auto"
                          className="project-achievement"
                        >
                          <Button variant="light" data-aos="fade-up">
                            {achievement}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Row>
                <Row className="px-3 mt-5 ">
                  {project.highlights && (
                    <Row className=" project-highlights px-3">
                      {project.highlights.map((highlight, index) => (
                        <Col
                          key={index}
                          xs="auto"
                          className="project-highlight m-1 p-0"
                          onMouseMove={handleMouseMoveExtra}
                          onMouseLeave={handleMouseLeaveExtra}
                        >
                          <Button variant="light" data-aos="fade-left">
                            {highlight}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  )}
                </Row>
                <Row className="px-4" data-aos="fade-left">
                  {project.description}
                </Row>
                <Row className="mt-3"><Col><Button data-aos="fade-left" className='project-highlight' variant='light' onClick={handleShow}>{"Know More"}</Button></Col></Row>
              </Row>
            </Col>
          </Row>
        ))
      ) : (
        <Row className="mt-5">
          <Col className="text-center">
            <img src={rathinLaptop}></img>
            <p className="mt-2">
              Oh, shoot! <br></br>Setting out to learn that skill promptly !
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ProjectFilterPage;

import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

import img1 from "./images/ucrLogo.png";
import img2 from "./images/maerskLogo.png";
import img3 from "./images/amritaLogo.png";
import img4 from "./images/exiaLogo.png";

function ExperienceScreen() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const getImagePath = (projectId) => {
    switch (projectId) {
      case 1:
        return img1;
      case 2:
        return img2;
      case 3:
        return img3;
      case 4:
        return img4;
      default:
        return ""; // Return a default image path or handle it as needed
    }
  };

  const handleMouseMove = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 15;
    const translateY = (offsetY / boundingRect.height - 0.5) * 15;

    card.style.transition = "transform 0.2s ease-out"; // Add a smooth transition

    card.style.transform = `
      translate(${translateX}px, ${translateY}px) 
    `;
  };
  const handleMouseMoveExtra = (event) => {
    console.log(event);
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 20;
    const translateY = (offsetY / boundingRect.height - 0.5) * 20;

    const rotateY = (offsetX / boundingRect.width - 0.5) * 15;
    const rotateX = (0.5 - offsetY / boundingRect.height) * 15;

    card.style.transition = "transform 0.5s ease-out"; // Add a smooth transition
    card.style.backgroundColor = "#2e2e2e";
    card.style.color = "white";
    card.style.padding = "1rem";
    card.style.transform = `
      translate(${translateX}px, ${translateY}px) scale(1.1) rotateX(${rotateX}deg) 
      rotateY(${rotateY}deg)
    `;
    card.style.borderRadius = "10px";
    card.style.boxShadow =
      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px";
    card.style.marginRight = "1rem";
  };
  const handleMouseLeave = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
  };
  const handleMouseLeaveExtra = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
    card.style.backgroundColor = "white";
    card.style.padding = "0rem";
    card.style.color = "black";
    card.style.marginRight = "0rem";
    card.style.boxShadow = "none";
  };
  const events = [
    {
      id: 1,
      date: "September 2023 - Present",
      title: "MS in Computer Science",
      description: "University of California, Riverside",
    },
    {
      id: 2,
      date: "January 2023 - August 2023",
      title: "Intern - Developer",
      description: "A.P. Moller Maersk",
    },
    {
      id: 3,
      date: "2019 - 2023",
      title: "B.Tech in Electrical and Computer Engineering",
      description: "Amrita School of Engineering, Coimbatore",
    },
    {
      id: 4,
      date: "June 2021 - January 2023",
      title: "Founder",
      description: "Exia Tech",
    },
    // Add more events as needed
  ];
  return (
    <Container style={{ backgroundColor: "white" }} className="py-5">
      <Row style={{ fontSize: "90%" }} className="mt-3 mx-1">
        <Col
          className="cont"
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          {events.map((event) => (
            <Row
              id={event.id}
              className="py-3 pb-4 timelineCard d-flex"
              onMouseMove={handleMouseMoveExtra}
              onMouseLeave={handleMouseLeaveExtra}
              data-aos="fade-up"
            >
              <Col style={{ maxWidth: "fit-content" }}>
                <div className="timelineCircle"></div>
                <img
                  src={getImagePath(event.id)}
                  style={{ backgroundColor: "white", borderRadius: "10px" }}
                  className="xsMarginRight"
                />
              </Col>
              <Col className="my-auto">
                <p
                  style={{ fontWeight: "500", fontSize: "80%" }}
                  className="my-0 py-0 mb-2"
                >
                  {event.date}
                </p>
                <h5 style={{ fontWeight: "bold" }} className="my-0 py-0">
                  <div className="timelineBiggerCircle"></div>
                  {event.title}
                </h5>
                <p style={{ fontSize: "90%" }} className="my-2 py-0">
                  {event.description}
                </p>
              </Col>
            </Row>
          ))}
        </Col>
        <Col></Col>
      </Row>
    </Container>
  );
}

export default ExperienceScreen;

import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Navbar, Nav, Container } from "react-bootstrap";
import rathinIcon from "../screens/images/rathinIcon.png";

function Footer() {
  return (
    <footer>
      <Container className="mt-5 pb-3">
        <p>
          Designed & Developed by <br></br> Rathin Raj R S <br></br> 2024
        </p>
      </Container>
    </footer>
  );
}

export default Footer;

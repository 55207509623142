import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Header from "./components/Header";
import AboutScreen from "./screens/AboutScreen";
import ExperienceScreen from "./screens/ExperienceScreen";
import HomeScreen from "./screens/HomeScreen";
import ProjectFilterPage from "./screens/ProjectFilterPage";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <Router>
        {" "}
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Navigate to="/about" />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="/experience" element={<ExperienceScreen />} />
            <Route path="/projects" element={<ProjectFilterPage />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

import React, { useEffect } from "react";
import { Container, Row, Col, Button, Navbar } from "react-bootstrap";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import Aos from "aos";
import "aos/dist/aos.css";

import rlogo from "../screens/images/rathinLogo_3.png";

function Header() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const handleMouseMove = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 30;
    const translateY = (offsetY / boundingRect.height - 0.5) * 30;

    card.style.transition = "transform 0.2s ease-out"; // Add a smooth transition

    card.style.transform = `
      translate(${translateX}px, ${translateY}px) scale(1.05) 
    `;
  };
  const handleMouseMoveExtra = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 50;
    const translateY = (offsetY / boundingRect.height - 0.5) * 50;

    const rotateY = (offsetX / boundingRect.width - 0.5) * 15;
    const rotateX = (0.5 - offsetY / boundingRect.height) * 15;

    card.style.transition = "transform 0.5s ease-out"; // Add a smooth transition
    card.style.transform = `
      translate(${translateX}px, ${translateY}px) scale(1.07) rotateX(${rotateX}deg) 
      rotateY(${rotateY}deg)
    `;
    card.style.borderRadius = "10px";
    card.style.marginRight = "1rem";
  };
  const handleMouseLeave = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
  };
  const handleMouseLeaveExtra = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
    card.style.backgroundColor = "white";
    card.style.padding = "0rem";
    card.style.color = "black";
    card.style.marginRight = "0rem";
    card.style.boxShadow = "none";
  };
  const loc = useLocation();

  const isActive = (path) => {
    if (loc.pathname === path) {
      return "active-link";
    }
    return "not-active";
  };
  return (
    <div>
      <Container className="background">
        <Row>
          <Col md={8} xl={6} xs={12} sm={12}>
            <Row className="mt-5">
              <img
                src={rlogo}
                onMouseMove={handleMouseMoveExtra}
                onMouseLeave={handleMouseLeaveExtra}
                data-aos="zoom-in"
                style={{
                  maxWidth: "20%",
                  WebkitMask:
                    "-webkit-gradient(linear, center top, center bottom, color-stop(0.00, rgba(0, 0, 0, 1)), color-stop(0.35, rgba(0, 0, 0, 1)), color-stop(0.50, rgba(0, 0, 0, 1)), color-stop(0.65, rgba(0, 0, 0, 1)), color-stop(1.00, rgba(0, 0, 0, 0)))",
                }}
                className="mb-0 pb-0"
              ></img>
              <h3
                style={{ fontWeight: "600" }}
                className="mb-0 pb-0 mt-0 pt-0 "
              >
                <NavLink to="/" className="nav-link" activeClassName="active">
                  Rathin Raj R S
                </NavLink>
              </h3>
              <p
                className="mt-2 pt-0 mb-0 pb-0 xsFontSize70"
                data-aos="fade-up"
              >
                Full Stack Developer&nbsp;&nbsp;
                <span style={{ opacity: "0.50" }} data-aos="fade-up">
                  ML/NLP Engineer
                </span>
                &nbsp;&nbsp;
                <span style={{ opacity: "0.25" }} data-aos="fade-up">
                  Design Enthusiast
                </span>
              </p>
              <p className="mt-n2 pt-0 mb-0 pb-0"></p>
            </Row>
            <Row className="mt-3 xsFontSize90">
              <p style={{ fontSize: "90%" }} data-aos="fade-up">
                I architect scalable software ecosystems, harmonize AI, and
                breathe life into pixel-perfect digital aesthetics
              </p>
            </Row>
            <Row className="mx-1">
              <Navbar data-aos="fade-up">
                <Navbar.Toggle aria-controls="navbar-nav" />

                <Navbar.Collapse id="navbar-nav">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <NavLink
                        to="/about"
                        className="nav-link"
                        activeClassName="active"
                      >
                        About
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/experience"
                        className="nav-link"
                        activeClassName="active"
                      >
                        Timeline
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/projects"
                        className="nav-link"
                        activeClassName="active"
                      >
                        Projects
                      </NavLink>
                    </li>
                  </ul>
                </Navbar.Collapse>
              </Navbar>
            </Row>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;

import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import HorizontalScrollingTextBar from "../components/HorizontalScrollingTextBar";
import Aos from "aos";
import "aos/dist/aos.css";
import gifPathOne from "./images/cardTopGif.gif";
import cardBottom from "./images/cardBottom_3.png";

function AboutScreen() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  const handleMouseMove = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 15;
    const translateY = (offsetY / boundingRect.height - 0.5) * 15;

    card.style.transition = "transform 0.2s ease-out"; // Add a smooth transition

    card.style.transform = `
      translate(${translateX}px, ${translateY}px) 
    `;
  };
  const handleMouseMoveExtra = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 20;
    const translateY = (offsetY / boundingRect.height - 0.5) * 20;

    const rotateY = (offsetX / boundingRect.width - 0.5) * 15;
    const rotateX = (0.5 - offsetY / boundingRect.height) * 15;

    card.style.transition = "transform 0.5s ease-out"; // Add a smooth transition
    card.style.backgroundColor = "#2c21fb";
    card.style.color = "white";
    card.style.padding = "1rem";
    card.style.transform = `
      translate(${translateX}px, ${translateY}px) scale(1.1) rotateX(${rotateX}deg) 
      rotateY(${rotateY}deg)
    `;
    card.style.borderRadius = "10px";
    card.style.boxShadow =
      "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px";
    card.style.marginRight = "1rem";
  };
  const handleMouseMoveGif = (event) => {
    const card = event.currentTarget;
    const boundingRect = card.getBoundingClientRect();

    const offsetX = event.clientX - boundingRect.left;
    const offsetY = event.clientY - boundingRect.top;

    const translateX = (offsetX / boundingRect.width - 0.5) * 30;
    const translateY = (offsetY / boundingRect.height - 0.5) * 30;

    const rotateY = (offsetX / boundingRect.width - 0.5) * 35;
    const rotateX = (0.5 - offsetY / boundingRect.height) * 35;

    card.style.transition = "transform 0.5s ease-out";
    card.style.color = "white";
    card.style.transform = `
      translate(${translateX}px, ${translateY}px) scale(1.1) rotateX(${rotateX}deg) 
      rotateY(${rotateY}deg)
    `;
  };
  const handleMouseLeave = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
  };
  const handleMouseLeaveExtra = (event) => {
    const card = event.currentTarget;
    card.style.transition = "transform 0.3s ease-in-out";
    card.style.transform = "none";
    card.style.backgroundColor = "white";
    card.style.padding = "0rem";
    card.style.color = "black";
    card.style.marginRight = "0rem";
    card.style.boxShadow = "none";
  };
  return (
    <div>
      <Container>
        <Row>
          <Col onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
            <Row className="mt-4">
              <Col md={6} xl={6} data-aos="fade-up">
                <p
                  style={{ fontSize: "90%", fontFamily: "cursive !important" }}
                  data-aos="fade-up"
                >
                  {" "}
                  My journey unfolded through the digital art realm, where I
                  discovered the profound impact of expressing ideas through
                  code and pixels. Driven by a deeper desire to make meaningful
                  contributions, I ventured into the vast world of technology.
                </p>
                <p
                  style={{ fontSize: "90%" }}
                  className="mt-2"
                  data-aos="fade-up"
                >
                  From crafting captivating user interfaces to delving into the
                  intricacies of machine learning, my pursuit has been to
                  harmonize design aesthetics with powerful functionality
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="float-end" xs={12} sm={12}>
                <img
                  onMouseMove={handleMouseMoveGif}
                  onMouseLeave={handleMouseLeaveExtra}
                  data-aos="fade-up"
                  src={gifPathOne}
                  alt="Card"
                  className="xsFullSize"
                />
              </Col>
              <Col xs={0} sm={0}></Col>
            </Row>

            <Row className="mt-5 mb-3" data-aos="fade-up">
              <Col>
                <Row>
                  <Col data-aos="fade-up">
                    <h6 style={{ fontSize: "90%" }}>SKILLS</h6>
                  </Col>
                </Row>
                <Row className="p-2">
                  <Col xl={6} md={6} xs={12} sm={12}>
                    <Row className="xsPaddingRight">
                      <Col
                        onMouseMove={handleMouseMoveExtra}
                        onMouseLeave={handleMouseLeaveExtra}
                        data-aos="fade-left"
                        xs={3}
                        className="xsFontSize90"
                      >
                        <h6 style={{ fontSize: "70%" }}>LANGUAGES</h6>
                        <Row style={{ fontSize: "80%" }}>
                          <p className="my-0 py-0">Python</p>
                          <p className="my-0 py-0">C++</p>
                          <p className="my-0 py-0">Javascript</p>
                          <p className="my-0 py-0">SQL</p>
                          <p className="my-0 py-0">HTML</p>
                          <p className="my-0 py-0">CSS, Sass</p>
                        </Row>
                      </Col>
                      <Col
                        onMouseMove={handleMouseMoveExtra}
                        onMouseLeave={handleMouseLeaveExtra}
                        data-aos="fade-left"
                        xs={3}
                        className="xsFontSize90"
                      >
                        <h6 style={{ fontSize: "70%" }}>FRAMEWORKS</h6>
                        <Row style={{ fontSize: "80%" }}>
                          <p className="my-0 py-0">Django</p>
                          <p className="my-0 py-0">Flask</p>
                          <p className="my-0 py-0">ReactJS</p>
                          <p className="my-0 py-0">AngularJS</p>
                          <p className="my-0 py-0">React Native</p>
                          <p className="my-0 py-0">Bootstrap</p>
                        </Row>
                      </Col>

                      <Col
                        onMouseMove={handleMouseMoveExtra}
                        onMouseLeave={handleMouseLeaveExtra}
                        data-aos="fade-left"
                        className="xsFontSize90"
                      >
                        <h6 style={{ fontSize: "70%" }}>ML/AI</h6>
                        <Row style={{ fontSize: "80%" }}>
                          <p className="my-0 py-0">R</p>
                          <p className="my-0 py-0">Tensorflow</p>
                          <p className="my-0 py-0">PyTorch</p>
                          <p className="my-0 py-0">Scikit-Learn</p>
                          <p className="my-0 py-0">Keras</p>
                          <p className="my-0 py-0">Numpy</p>
                        </Row>
                      </Col>
                      <Col
                        onMouseMove={handleMouseMoveExtra}
                        onMouseLeave={handleMouseLeaveExtra}
                        data-aos="fade-left"
                        className="xsFontSize90"
                      >
                        <h6 style={{ fontSize: "70%" }}>TOOLS</h6>
                        <Row style={{ fontSize: "80%" }}>
                          <p className="my-0 py-0">PostgreSQL</p>
                          <p className="my-0 py-0">Docker</p>
                          <p className="my-0 py-0">Spark</p>
                          <p className="my-0 py-0">Git</p>
                          <p className="my-0 py-0">Postman</p>
                          <p className="my-0 py-0">Wireshark</p>
                        </Row>
                      </Col>
                    </Row>

                    <Col></Col>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Row className="mt-5">
            <Col md={6} xl={6}></Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
}

export default AboutScreen;
